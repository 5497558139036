









import { computed, defineComponent } from "@vue/composition-api";
import useWatchPropsValue from "@/apps/core/components/useWatchPropsValue";

const hasilMap: Record<string, Record<string, string>> = {
  "": { class: "", icon: "" },
  Lulus: { class: "has-text-success", icon: "check" },
  "Tidak Lulus": { class: "has-text-danger", icon: "times" },
  Belum: { class: "has-text-info", icon: "minus" },
  Ya: { class: "has-text-success", icon: "check" },
  Tidak: { class: "has-text-danger", icon: "times" },
};

const getClassIcon = (value: string | undefined | null) => {
  const key = value ?? "";
  return hasilMap[key.toString()];
}

export default defineComponent({
  name: "HasilMark",
  props: {
    value: String,
  },
  setup(props) {
    const { valueRef } = useWatchPropsValue(props);
    // When v-model is changed: update internal value
    return {
      valueRef,
      hasilClass: computed(() => getClassIcon(valueRef.value).class),
      hasilIcon: computed(() => getClassIcon(valueRef.value).icon),
    };
  },
});
