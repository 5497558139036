import { ref, watch } from "@vue/composition-api";

const useWatchPropsValue = (
  props: Record<string, string | undefined | null>
) => {
  const valueRef = ref(props.value);
  // When v-model is changed: update internal value
  watch(
    () => props.value,
    (newValue) => {
      if (valueRef.value !== newValue) {
        valueRef.value = newValue;
      }
    }
  );

  return { valueRef };
};

export default useWatchPropsValue;
